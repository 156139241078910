import VueRouter, { Route } from 'vue-router'
import Component from './index.vue'
import qs from 'qs'

const routes: any[] = [
  {
    path: '/',
    component: Component,
    props: ({ query }: Route) => {
      return {
        hole: query.hole,
        size: query.size,
        variant: query.variant,
        materials: query.materials,
        quantity: query.quantity,
        amount: query.amount,
        reflected: String(query.reflected).toLowerCase() === 'true',
        pairs: String(query.pairs).toLowerCase() === 'true',
      }
    },
  },
]

const router = new VueRouter({
  base: _state.baseUrl,
  mode: 'history',
  routes,
  parseQuery: (query: any): object => {
    return qs.parse(query)
  },
  stringifyQuery: (query: any): string => {
    const result = qs.stringify(query, { encodeValuesOnly: true })
    return result ? '?' + result : ''
  },
})

export default router
