import { Component, Prop, Vue } from 'vue-property-decorator'
import bus from '@shared/bus'
import store from 'store'
import {Variant} from '@shared/interfaces'

@Component
export default class ShapeIssue extends Vue {
  @Prop({ required: true }) variant: Variant

  private isActive: boolean = false

  public logIssue() {
    this.isActive = !this.isActive
    if(this.isActive){
      this.logVariantProblem()
    }
  }

  public get holderClasses(): string[] {
    return [this.isActive ? 'active' : '']
  }

  private logVariantProblem() {
    const sheetURL = 'https://script.google.com/macros/s/AKfycbyNxVwmJZ9lsWtFYyZFda6-LyKio82qabv48Z9b_4SC9VRPMGxHXLU-uELxmKfrnIJBRQ/exec'
    const data = {
      action: 'shapeIssue',
      shapeId: this.variant.id,
      shapeName: this.variant.title,
      variantURL: this.variant.url,
      variantSize: this.variant.size.label,
      variantHole: this.variant.holeType,
    }

    fetch(sheetURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain;charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        return response.json()
      })
      .then(d => {
        // Log the parsed data from the response
        // tslint:disable-next-line:no-console
        console.log('Data:', d)
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.error('Error:', error)
      })
  }
}
