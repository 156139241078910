import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { http } from '@ts/plugins/http'
import { Variant, VariantsizeOption } from '@ts/shared/interfaces'
import MixinShape from '@ts/mixins/Shape'
import { formatPrice, isObjectPropSelected, buildNameFromProperties } from '@ts/shared/helpers'
import { getSnipcartInstance } from '@plugins/snipcart'
import bus from '@shared/bus'
import vOption from './option/index.vue'

@Component({
  components: {
    'v-option': vOption,
  },
})
export default class ShapeView extends mixins(MixinShape) {
  @Prop() variant: string
  @Prop() quantity: string
  @Prop({ default: (): [] => [] }) materials: any
  @Prop({ default: false, type: Boolean }) reflected: any
  @Prop({ default: false, type: Boolean }) pairs: any
  @Prop() hole: string
  @Prop() size: string
  @Prop() amount: string

  private orderQuantity: number = 1
  private holePreviews: any[] = _state.holeTypePreviews
  private variantObject: Variant = _state.variant
  private variantSizeOptions: VariantsizeOption[] = _state.variantSizeOptions
  private quantityOptions: number[] = _state.quantityOptions
  private snipcart: any = null
  private loadingPrice = false

  private get orderDescriptionHtml() {
    return buildNameFromProperties(
      this.quantity,
      this.selectedMaterials,
      this.variantObject,
      this.pairs,
      this.reflected,
      true,
    )
  }
  private get orderDescription() {
    return buildNameFromProperties(
      this.quantity,
      this.selectedMaterials,
      this.variantObject,
      this.pairs,
      this.reflected,
      false,
    )
  }

  private get hasVariant(): boolean {
    return this.variantObject !== null
  }

  private get price(): string {
    return formatPrice(this.variantObject.price * this.orderQuantity)
  }

  private get canOrder() {
    const checks = [this.variantObject.price, this.size, this.quantity, this.hole]
    const isSnipcartReady = this.snipcart !== null

    return checks.every((check) => check !== undefined) && isSnipcartReady
  }

  private get holeDescription() {
    return this.variantObject.holeDescription
  }

  private get hasHoleDescription() {
    return this.holeDescription !== null && this.holeDescription.length > 0
  }

  private get selectedSizeOption() {
    return this.variantSizeOptions.find((option) => option.hash === this.variant)
  }

  private get selectedHolePreview() {
    return this.holePreviews.find((preview) => preview.hash === this.variant)
  }

  private get isOptionSelected() {
    return (key: string, index?: number) => {
      return isObjectPropSelected(this.$props, key, index)
    }
  }

  private get selectedMaterialDescriptions() {
    return this.selectedMaterials
      .filter((material) => material !== null)
      .map(({ shapeDescription, title, route }) => {
        return {
          shapeDescription,
          title,
          route,
        }
      })
  }

  private get quantityOption() {
    let quantity = parseInt(this.quantity, 10)

    if (!this.quantity) {
      quantity = 10
    }

    let label = `${quantity} shapes`

    if (this.pairs) {
      label = `${quantity / 2} pairs`
    }

    return {
      quantity,
      reflected: this.reflected,
      pairs: this.pairs,
      label,
    }
  }

  private selectQuantity(quantity: { quantity: number; reflected: boolean; pairs: boolean }) {
    this.updateUrl(quantity)
  }

  protected async updateVariant({ hash, updates = {} }: { hash: string; updates?: any }) {
    const response = await http.get(`shapes/${this.variant}`, { params: { variant: hash } })
    const data = response.data.data
    const meta = response.data.meta

    this.variantObject = data
    this.variantSizeOptions = [...meta.variantSizeOptions]
    this.holePreviews = [...meta.holeTypePreviews]

    const props = { ...this.$props, ...updates }

    // add validation to props...
    this.updateUrl({ ...props, variant: data.hash })
  }

  protected async fetch(props: any = {}) {
    this.loadingPrice = true
    Object.assign(this.props, props)

    const response = await http.get(`shapes/${this.variant}`, { params: { ...this.props } })
    const data = response.data.data

    this.variantObject = data
    this.loadingPrice = false
  }

  private checkOrderQuantity() {
    if (this.orderQuantity < 1) {
      this.orderQuantity = 1
    }

    this.updateUrl({ amount: this.orderQuantity })
  }

  private addToCart() {
    this.snipcart.addToCart({
      id: this.variantObject.id,
      name: this.orderDescription,
      description: this.variantObject.description,
      price: this.variantObject.price,
      url: this.variantObject.url,
      quantity: this.orderQuantity,
      image: this.variantObject.previewImage,
      stackable: 'never',
      hasTaxesIncluded: true,
      categories: this.tagsToSnipcartCategories(this.variantObject.tags),
      metadata: {
        type: 'shape',
        hash: this.variantObject.hash,
        shapeHash: this.variantObject.shapeHash,
        materialHashes: this.selectedMaterialHashes,
        quantity: parseInt(this.quantity, 10),
        reflected: this.reflected,
        pairs: this.pairs,
        pageUrl: window.document.URL,
      },
    })
  }

  private tagsToSnipcartCategories(tags: object) :string[] {
    return Object.keys(tags)
  }

  private mounted() {
    this.setupMaterials()
    this.setupProps()

    const props = {
      material: this.material,
      quantity: this.quantity,
    }

    if (this.amount) {
      this.orderQuantity = parseInt(this.amount, 10)
    }

    if (this.havePropsChanged(props)) {
      this.updateUrl(props, true)
    }

    getSnipcartInstance().then((snipcart) => {
      this.snipcart = snipcart
    })

    bus.$on(['login', 'logout'], this.fetch)
  }
}
