class Snipcart {
  public sdk: any

  constructor() {
    this.sdk = (window as any).Snipcart
  }

  public get store() {
    return this.sdk.store
  }

  public get state() {
    return this.store.getState()
  }

  public get cart() {
    return this.api.cart
  }

  public get ready() {
    return this.sdk.ready
  }

  public get api() {
    return this.sdk.api
  }

  public get appliedDiscounts() {
    return this.state.cart.discounts.items || []
  }

  public get hasItems() {
    return this.state.cart.items.items.length > 0
  }

  public get cartItemUrl() {
    return (cartItem: any) => {
      const url = new URL(cartItem.url)
      url.searchParams.set('amount', cartItem.quantity)

      return url.toString()
    }
  }

  public get hasCartItemUrlChanged() {
    return (cartItem: any) => {
      const currentItem = this.findCartItem(cartItem.uniqueId)
      return currentItem !== undefined && this.cartItemUrl(cartItem) !== currentItem.url
    }
  }

  public findCartItem(uniqueId: string) {
    return this.state.cart.items.items.find((item: any) => item.uniqueId === uniqueId)
  }

  public addToCart(item: any) {
    return this.cart.items.add(item)
  }

  public updateCartItem(payload: any) {
    return this.cart.items.update(payload)
  }

  public on(event: string, callbacks: any) {
    if (!Array.isArray(callbacks)) {
      callbacks = [callbacks]
    }

    callbacks.forEach((callback: any) => {
      this.sdk.events.on(event, callback)
    })
  }

  public off(event: string, callbacks: any) {
    if (!Array.isArray(callbacks)) {
      callbacks = [callbacks]
    }

    callbacks.forEach((callback: any) => {
      this.sdk.events.on(event, callback).unsubscribe()
    })
  }
}

const getSnipcartInstance = (): Promise<Snipcart> => {
  return new Promise((resolve) => {
    if ((window as any).Snipcart !== undefined) {
      const instance = new Snipcart()
      instance.ready.then(() => resolve(instance))
    }

    document.addEventListener('snipcart.ready', () => {
      const instance = new Snipcart()
      instance.ready.then(() => resolve(instance))
    })
  })
}

export { getSnipcartInstance, Snipcart }
