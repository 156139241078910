import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Collapse extends Vue {
  private isCollapsed = false

  private toggle() {
    this.isCollapsed = !this.isCollapsed
  }
}
