import { Component, Prop, Vue } from 'vue-property-decorator'
import bus from '@shared/bus'
import store from 'store'

@Component
export default class Favourite extends Vue {
  @Prop({ required: true }) type: string
  @Prop({ required: true }) hash: string

  private isActive: boolean = false

  public toggleFavourite() {
    this.isActive = !this.isActive

    bus.$emit('favourite', {
      type: this.type,
      hash: this.hash,
      active: this.isActive,
    })
  }

  public get holderClasses(): string[] {
    return [this.isActive ? 'active' : '']
  }

  public mounted() {
    this.checkState()

    bus.$on('favourite-sync', this.checkState)
  }

  private checkState() {
    const list = store.get(`favourites:${this.type}`)
    if (list && Array.isArray(list)) {
      this.isActive = list.includes(this.hash)
    }
  }
}
