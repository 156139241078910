import Vue from 'vue'
import Component from 'vue-class-component'
import { getSnipcartInstance } from '@plugins/snipcart'
import { http } from '@ts/plugins/http'
import debounce from 'lodash.debounce'

interface DiscountInterface {
  amount: number
  code: string
  description: string
}

const FLITS_MESSAGE = 'checking for discount...'

@Component
export default class Discounts extends Vue {
  private discount: DiscountInterface | null = null
  private snipcart: any
  private isInitialLoad = true
  private cartToken: string | null = null
  private cartListeners: any[] = []

  private get cart() {
    return this.snipcart.cart
  }

  public get activeDiscountByCode() {
    return (code: string) => this.snipcart.state.cart.discounts.items.find((discount: any) => discount.code === code)
  }

  private showMessage() {
    if (this.isInitialLoad) {
      this.isInitialLoad = false
      return
    }

    return this.$flits.info(FLITS_MESSAGE)
  }

  private removeMessage() {
    const flitsItem = this.$flits.items.find((item) => item.message === FLITS_MESSAGE)

    if (flitsItem) {
      this.$flits.remove(flitsItem)
    }
  }

  private quantityUpdate(cartItem: any) {
    const url = this.snipcart.cartItemUrl(cartItem)

    http.get(url).then(async (response) => {
      await this.snipcart.updateCartItem({
        uniqueId: cartItem.uniqueId,
        url,
        price: response.data.price,
      })
    })
  }

  private resetCartToken() {
    this.cartToken = null
  }

  private checkCartTokenState() {
    /**
     * The cart token gets reset after and order has completed
     * When this happens we need to re-initialize it from
     * the state.
     */
    if (this.cartToken !== null) {
      return
    }

    this.cartToken = this.snipcart.state.cart.token
  }

  private updateCartButtons(action: 'add' | 'remove', cssClass: string) {
    const buttons = [
      document.querySelector('.snipcart-item-quantity__quantity'),
      document.querySelector('.snipcart-cart__checkout-button'),
    ] as HTMLElement[]

    buttons //
      .filter((button) => button !== undefined && button !== null)
      .forEach((button) => button.classList[action](cssClass))
  }

  private disableButtons() {
    this.updateCartButtons('add', 'disabled')
  }

  private enableButtons() {
    this.updateCartButtons('remove', 'disabled')
  }

  public getDiscounts() {
    this.checkCartTokenState()
    this.showMessage()
    this.disableButtons()

    http
      .get(`/discounts/${this.cartToken}`)
      .then((response) => {
        this.discount = response.data

        try {
          if (this.discount.amount > 0) {
            this.syncDiscounts()
          } else {
            const previousDiscount = this.activeDiscountByCode(this.cartToken)

            if (previousDiscount) {
              this.cart.removeDiscount(this.cartToken)
            }

            this.enableButtons()
            this.removeMessage()
          }
        } catch (error) {
          this.$flits.error(error)
        }
      })
      .catch((error) => {
        this.$flits.error(error)
        this.enableButtons()
      })
  }

  public async syncDiscounts() {
    const activeDiscount = this.activeDiscountByCode(this.discount.code)

    if (activeDiscount && activeDiscount.amount === this.discount.amount) {
      return this.enableButtons()
    }

    if (activeDiscount) {
      await this.cart.removeDiscount(this.discount.code)
    }

    this.cart.applyDiscount(this.discount.code)
    this.enableButtons()
  }

  private qtyListener(cartItem?: any) {
    if (cartItem !== undefined && this.snipcart.hasItems && this.snipcart.hasCartItemUrlChanged(cartItem)) {
      return this.quantityUpdate(cartItem)
    }
  }

  private async applyShippingDiscount(discount?:any) {
    // tslint:disable-next-line:no-console
    console.log(discount)
    if (discount.code === '10FREE') {
      try {
        await this.snipcart.api.cart.applyDiscount('FREESHIP10FREE')
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log(error)
      }
    }
  }

  public bindEvents() {
    this.snipcart.on('discount.applied', this.applyShippingDiscount)

    if (!_state.areMaterialAreaDiscountsEnabled && !_state.areQuantityDiscountsEnabled) {
      return
    }

    if (_state.areQuantityDiscountsEnabled) {
      this.cartListeners.push(this.qtyListener)
    }

    if (_state.areMaterialAreaDiscountsEnabled) {
      this.cartListeners.push(debounce(this.getDiscounts, 1000))
    }

    this.cartListeners.push(this.disableButtons)

    this.snipcart.on('item.added', this.cartListeners)
    this.snipcart.on('item.updated', this.cartListeners)
    this.snipcart.on('item.removed', this.cartListeners)
    this.snipcart.on('cart.confirmed', this.resetCartToken)
    this.snipcart.on('discount.applied', this.removeMessage)
  }

  public mounted() {
    getSnipcartInstance().then((instance) => {
      this.snipcart = instance
      this.bindEvents()

      if (instance.state.cart.token) {
        this.cartToken = instance.state.cart.token
        this.cartListeners.forEach((listener) => listener())
      }
    })
  }
}
