import Vue from 'vue'
import Component from 'vue-class-component'
import { http } from '@ts/plugins/http'
import bus from '@shared/bus'
import store from 'store'

interface FavouriteEvent {
  type: string
  hash: string
  active: boolean
}

const favouriteTypes = ['shape-variant', 'material']

@Component
export default class Favourites extends Vue {
  mounted() {
    bus.$on(['login'], this.syncFavourites)

    bus.$on(['favourite'], (event: FavouriteEvent) => {
      const favType = event.type
      const favs = this.listAllFavourites()
      if (!favs[favType]) {
        return
      }

      let list = favs[favType]

      if (!event.active) {
        list = list.filter((item: string) => item !== event.hash)
        this.deleteFavourite(event)
      } else {
        list.push(event.hash)
        this.createFavourite(event)
      }

      store.set(`favourites:${favType}`, list)
    })
  }

  private deleteFavourite(event: FavouriteEvent) {
    if (!_state.isLoggedIn) {
      return
    }

    http.delete('/favourites', {
      data: {
        type: event.type,
        hash: event.hash,
      },
    })
  }

  private createFavourite(event: FavouriteEvent) {
    if (!_state.isLoggedIn) {
      return
    }

    http.post('/favourites', {
      type: event.type,
      hash: event.hash,
    })
  }

  public listAllFavourites(): any {
    const favs: any = {}

    favouriteTypes.forEach((type) => {
      let list = store.get(`favourites:${type}`)
      if (!list || !Array.isArray(list)) {
        list = []
      }

      favs[type] = list
    })

    return favs
  }

  private syncFavourites() {
    if (!_state.isLoggedIn) {
      return
    }

    const favs = this.listAllFavourites()

    http.get('/favourites').then((response) => {
      const data = response.data
      for (const key in data) {
        if (!data.hasOwnProperty(key)) {
          continue
        }

        const f = favs[key]
        const list = f.concat(data[key].filter((item: any) => f.indexOf(item) < 0))

        // TODO: once we have sync'd locally we should push the entire thing back to the server

        store.set(`favourites:${key}`, list)
      }
      bus.$emit('favourite-sync')
    })
  }
}
