import { Variant, Material } from './interfaces'

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(price)
}

const isObjectPropSelected = (object: any, key: string, index?: number) => {
  let prop = object[key]

  if (index !== undefined) {
    prop = prop[index]
  }

  if (typeof prop === 'string' && prop.length === 0) {
    prop = undefined
  }

  return (
    prop !== undefined && prop !== null //
  )
}

const buildNameFromProperties = (
  quantity: string,
  materials: Material[],
  variant: Variant,
  pairs: boolean = false,
  reflected: boolean = false,
  asHtml: boolean = false,
) => {
  const materialDescription = materials
    .filter((material) => material !== null)
    .map((material) => material.title)
    .join(', ')

  if (pairs) {
    quantity = `${parseInt(quantity, 10) / 2} pairs`
  }

  const reflectedString = reflected //
    ? 'all mirrored '
    : ''

  if(asHtml){
    return `${quantity} &times; ${variant.size.label} <br>${variant.title} (${reflectedString}with ${variant.holeType})<br> cut from ${materialDescription}`
  } else {
    return `${quantity} &times; ${variant.size.label} ${variant.title} (${reflectedString}with ${variant.holeType}) cut from ${materialDescription}`
  }
}

export { formatPrice, isObjectPropSelected, buildNameFromProperties }
