import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Messages extends Vue {
  @Prop({ default: (): any[] => [] }) messages: any[]

  private activeIndex = 0
  private timeout = 5000

  get messageStyling() {
    return (message: any) => {
      return {
        background: message.label_color,
        color: message.text_color,
      }
    }
  }

  get isActive() {
    return (index: number) => {
      return this.activeIndex === index
    }
  }

  get hasMessages() {
    return this.messages.length > 0
  }

  private nextMessage() {
    if (this.activeIndex === this.messages.length - 1) {
      this.activeIndex = 0
    } else {
      this.activeIndex++
    }
  }

  private mounted() {
    setInterval(this.nextMessage, this.timeout)
  }
}
