import Vue, { VueConstructor } from 'vue'
import Flits from '@plugins/flits'
import '@shared/components'
import MixinSnipcart from '@ts/mixins/Snipcart'
import MixinFavourites from '@ts/mixins/Favourites'
import MixinDiscounts from '@ts/mixins/Discounts'

Vue.use(Flits)

export default class Applicaton {
  protected vm: Vue

  public constructor(modules: any = [], router: any = null) {
    this.requireIcons()

    if (!Array.isArray(modules)) {
      modules = [modules]
    }

    modules.push(MixinSnipcart)
    modules.push(MixinFavourites)
    modules.push(MixinDiscounts)

    if (router) {
      this.loadRouter().then(() => {
        this.initVue(modules, { router })
      })
    } else {
      this.initVue(modules)
    }
  }

  private async loadRouter() {
    const router = await import('vue-router')
    Vue.use(router.default)
  }

  private initVue(modules: VueConstructor[], plugins: { router?: any } = {}) {
    this.vm = new Vue({
      name: 'App',
      mixins: modules,
      ...plugins,
      data: () => {
        return {
          isMobileMenuOpen: false,
        }
      },
      methods: {
        togggleMobileMenu() {
          document.body.classList.toggle('mobile-menu-open')
          this.isMobileMenuOpen = !this.isMobileMenuOpen
        },
        focusInput(event: InputEvent) {
          const searchInput = this.$refs.searchInput as HTMLInputElement
          const target = event.currentTarget as HTMLInputElement
          if (target.checked) {
            searchInput.focus()
          }
        },
      },
    })

    this.vm.$mount('#simple-shapes-app')
  }

  private requireIcons() {
    const div = document.createElement('div')
    const body = document.body
    const cssString =
      'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'

    div.style.cssText = cssString
    div.innerHTML = require('!!html-loader!../../img/icons.svg')
    body.insertBefore(div, body.childNodes[0])
  }
}
