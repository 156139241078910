import { flits } from '@plugins/flits'
import axios from 'axios'
import qs from 'qs'

const http = axios.create({
  baseURL: '/api',
  timeout: 50000,
  paramsSerializer: (params: any) => qs.stringify(params, { encodeValuesOnly: true }),
})

export class HttpError extends Error {
  url = ''
  payload = ''
  status = 0

  constructor(message: string) {
    super(message)
    this.name = 'HttpError'
  }
}

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const response: any = error.response
    let customMessage: string = ''

    if (response && response.status) {
      const data: any = response.data

      switch (response.status) {
        case 401:
        case 403:
        case 422:
          if (Array.isArray(data.errors)) {
            data.errors.forEach((messages: string[]) => {
              messages.forEach((message: string) => {
                flits.error(message)
                customMessage += `${message}\n`
              })
            })
          } else if (data.errors && Object.keys(data.errors).length > 0) {
            Object.keys(data.errors).forEach((key: string) => {
              data.errors[key].forEach((message: string) => {
                flits.error(message)
                customMessage += `${message}\n`
              })
            })
          } else if (data.message && data.message.length > 0) {
            flits.error(data.message)
            customMessage += `${data.message}\n`
          }

          break
      }
    }

    const errorMessage = error.message + '\n' + customMessage

    const httpError = new HttpError(errorMessage.trim())
    httpError.url = error.config.url
    httpError.payload = error.config.data
    httpError.stack = error.stack
    httpError.status = response && response.status ? response.status : 0

    return Promise.reject(httpError)
  },
)

export { http }
