import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mixin as clickaway } from 'vue-clickaway'

@Component({
  mixins: [clickaway],
})
export default class Option extends Vue {
  @Prop({ default: '' }) icon!: string
  @Prop({ default: '' }) placeholder!: string
  @Prop({ required: false, default: null }) selectedOption!: any
  @Prop({ default: false }) isSelected!: boolean

  private isVisible = false

  private get label() {
    if (!this.selectedOption) {
      return this.placeholder
    }

    if (this.selectedOption.hasOwnProperty('title')) {
      return this.selectedOption.title
    }

    if (this.selectedOption.hasOwnProperty('label')) {
      return this.selectedOption.label
    }

    if (this.selectedOption.hasOwnProperty('holeDescription')) {
      return this.selectedOption.holeDescription
    }

    return this.selectedOption
  }

  private get hasSelectedOption() {
    return this.selectedOption !== null
  }

  private toggleVisibility() {
    this.isVisible = !this.isVisible
  }

  private hide() {
    this.isVisible = false
  }

  private clicked(event: MouseEvent) {
    const composedPath = event.composedPath()
    const relatedTargets = composedPath.slice(0, 3)

    if (relatedTargets.some((el) => el instanceof HTMLInputElement || el instanceof HTMLLabelElement)) {
      return
    }

    this.hide()
  }

  private reset() {
    this.$emit('option-reset')
    this.toggleVisibility()
  }
}
