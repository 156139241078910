import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

interface QuantityInterface {
  quantity: number
  reflected: boolean
  pairs: boolean
}

@Component
export default class Quantity extends Vue {
  @Prop() options!: number[]
  @Prop({ default: null }) selectedOption!: QuantityInterface

  private reflected: boolean = false
  private pairs: boolean = false

  private get isSelected() {
    return (option: number) => option === this.selectedOption.quantity
  }

  @Watch('selectedOption', { immediate: true })
  private selectedOptionWatcher(selectedOption: QuantityInterface) {
    this.reflected = selectedOption.reflected
    this.pairs = selectedOption.pairs

    // Not allowed
    if (this.reflected && this.pairs) {
      this.reflected = false
      this.pairs = false

      this.select()
    }
  }

  private updatePairs() {
    if (this.pairs) {
      this.reflected = false
    }

    this.select()
  }

  private updateReflected() {
    if (this.reflected) {
      this.pairs = false
    }

    this.select()
  }

  private get label() {
    return this.pairs ? 'pairs' : 'shapes'
  }

  private getAmount(option: number) {
    return this.pairs ? option / 2 : option
  }

  private select(option: number = this.selectedOption.quantity) {
    this.$emit('input', {
      quantity: option,
      reflected: this.reflected,
      pairs: this.pairs,
    })
  }
}
