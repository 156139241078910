import Vue from 'vue'
import Component from 'vue-class-component'
import { http } from '@ts/plugins/http'
import { Prop } from 'vue-property-decorator'
import { Material as MaterialInterface } from '@ts/shared/interfaces'

@Component
export default class Material extends Vue {
  @Prop({ default: null }) selectedMaterial!: MaterialInterface
  @Prop({ default: null }) shapeCategory!: string

  $refs!: {
    search: HTMLInputElement,
  }

  private materials: MaterialInterface[] = []
  private searchQuery: string = ''

  private hoveredMaterial: string = null
  private showFavourites = false

  private get hasHoveredMaterial(): boolean {
    return this.hoveredMaterial !== null
  }

  private get hasSelectedMaterial(): boolean {
    return this.selectedMaterial !== null
  }

  private get isSelected() {
    return (material: MaterialInterface) => {
      return this.selectedMaterial ? material.hash === this.selectedMaterial.hash : false
    }
  }

  private get filteredMaterials(): any {
    let filtered = this.materials
      .filter((material) => material.image !== null)
      .filter((material) =>
        this.searchQuery ? material.title.toLowerCase().includes(this.searchQuery.toLowerCase()) : true,
      )
      .filter((material) => (this.shapeCategory ? material.availability[this.shapeCategory] : true))

    if (this.showFavourites) {
      const favourites = (this.$root as any).listAllFavourites().material
      filtered = filtered.filter((material) => favourites.includes(material.hash))
    }

    return filtered
  }

  private get hasMaterials(): boolean {
    return this.materials !== null
  }

  private toggleFavourites() {
    this.showFavourites = !this.showFavourites
  }

  private selectMaterial(material: MaterialInterface) {
    if (this.isSelected(material)) {
      return
    }

    this.$emit('select-material', material)
  }

  private setHoveredMaterial(material: MaterialInterface) {
    if (!material) {
      this.hoveredMaterial = null
    } else {
      this.hoveredMaterial = material.title
    }
  }

  private async fetchData() {
    const response = await http.get('/materials')
    this.materials = response.data.data
  }

  private mounted() {
    this.fetchData()
  }
}
