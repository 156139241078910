import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Material, SizeOption, ShapeProps } from '@ts/shared/interfaces'

@Component
export default class MixinShape extends Vue {
  @Prop() material!: string

  protected selectedMaterials: (Material | null)[] = []
  protected materialsCount: number = _state.materialsCount
  protected props: ShapeProps = {}

  @Watch('$route')
  private routeWatcher() {
    this.fetch()
  }

  protected get firstSelectedMaterial() {
    return this.selectedMaterials.find((material) => material !== null)
  }

  protected get selectedMaterialHashes(): string[] {
    return this.selectedMaterials.map((material) => (material ? material.hash : null))
  }

  protected emptySelectedMaterials() {
    this.selectedMaterials = new Array(this.materialsCount).fill(null)
  }

  protected removeProperty(key: string) {
    delete this.props[key]
    this.updateUrl()
  }

  private setMaterial(material: Material | null, index: number) {
    this.selectedMaterials.splice(index, 1, material)
    const noMaterials = this.selectedMaterials.every((m) => m === null)

    if (!noMaterials) {
      this.updateUrl({ materials: this.selectedMaterialHashes })
    } else {
      this.removeProperty('materials')
    }
  }

  protected updateUrl(props: any = {}, replace: boolean = false) {
    const action = replace ? 'replace' : 'push'
    Object.assign(this.props, props)

    return this.$router[action]({ path: '/', query: { ...this.props } })
  }

  protected setupMaterials() {
    if (_state.selectedMaterials) {
      return (this.selectedMaterials = _state.selectedMaterials)
    }

    this.emptySelectedMaterials()
  }

  protected async fetch(props: any = {}) {
    // override
  }

  protected havePropsChanged(props: { [key: string]: any }) {
    return Object.keys(props).some((prop) => {
      return String(this.props[prop]) !== String(props[prop])
    })
  }

  protected setupProps() {
    Object.keys(this.$props).forEach((prop) => {
      const value = this.$props[prop]

      if (value) {
        this.props[prop] = value
      }
    })
  }
}
