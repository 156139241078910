import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Select extends Vue {
  @Prop({ required: true }) private options: any[]
  @Prop({ default: '' }) private placeholder: string
  @Prop() private valueKey: string
  @Prop() private labelKey: string

  private get hasOptions() {
    return this.options !== null && (this.options.length > 0 || Object.keys(this.options).length > 0)
  }

  private get hasValueKey() {
    return this.valueKey !== undefined
  }

  private get hasLabelKey() {
    return this.labelKey !== undefined
  }

  private get hasValue() {
    return this.$attrs.value !== undefined
  }

  private clear() {
    this.$emit('clear-select')
  }
}
