import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class Holes extends Vue {
  @Prop() options!: any[]
  @Prop({ default: null }) selectedOption!: any

  private get maskStyle() {
    return (option: any) => {
      return {
        'mask-image': `url(${option.preview})`,
      }
    }
  }

  private select(option: any) {
    this.$emit('input', { hash: option.hash, updates: { hole: option.holeType } })
  }

  private isOptionSelected(option: any) {
    return option.hash === this.selectedOption.hash
  }
}
