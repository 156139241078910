import Vue from 'vue'
import Component from 'vue-class-component'
import { mixin as clickaway } from 'vue-clickaway'

@Component({
  mixins: [clickaway],
})
export default class NavItem extends Vue {
  private isExpanded = false
  private mq = window.matchMedia('(min-width: 1024px)')
  private requiresExpansion = true

  private toggleExpanded() {
    this.isExpanded = !this.isExpanded
  }

  private hide() {
    this.isExpanded = false
  }

  private changeListener(mq: MediaQueryListEvent) {
    this.requiresExpansion = mq.matches
  }

  private mounted() {
    const mq = window.matchMedia('(min-width: 1024px)')
    this.requiresExpansion = mq.matches
    mq.addEventListener('change', this.changeListener)
  }
}
