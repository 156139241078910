import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import * as basicLightbox from 'basiclightbox'

@Component
export default class VFilter extends Vue {
  @Prop({ default: false }) activateFilters!: boolean

  public isActive: boolean = false

  private toggleActive() {
    this.isActive = !this.isActive
    this.$emit('filters-open', this.isActive)
  }

  private closeFilters() {
    this.isActive = false
    this.$emit('filters-open', this.isActive)
  }

  private mounted() {
    window.addEventListener('keyup', this.keyListener)
    if (this.activateFilters) {
      this.toggleActive()
    }
  }

  private destroyed() {
    window.removeEventListener('keyup', this.keyListener)
  }

  private keyListener = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.closeFilters()
    }
  }
}
