import Vue from 'vue'
import vIcon from '@components/v-icon/index.vue'
import vMaterial from '@components/v-material/index.vue'
import vFavourite from '@components/v-favourite/index.vue'
import vShapeIssue from '@components/v-shape-issue/index.vue'
import vShapeVariant from '@components/v-shape-variant/index.vue'
import vFilter from '@components/v-filter/index.vue'
import vSizes from '@components/v-sizes/index.vue'
import vQuantity from '@components/v-quantity/index.vue'
import vHoles from '@components/v-holes/index.vue'
import vNavItem from '@components/v-nav-item/index.vue'
import vMessages from '@components/v-messages/index.vue'
import vCollapse from '@components/v-collapse/index.vue'
import vSelect from '@components/v-select/index.vue'
import vTooltip from '@components/v-tooltip/index.vue'
import vNumber from '@components/v-number/index.vue'

Vue.component('v-favourite', vFavourite)
Vue.component('v-filter', vFilter)
Vue.component('v-icon', vIcon)
Vue.component('v-material', vMaterial)
Vue.component('v-shape-issue', vShapeIssue)
Vue.component('v-shape-variant', vShapeVariant)
Vue.component('v-sizes', vSizes)
Vue.component('v-quantity', vQuantity)
Vue.component('v-holes', vHoles)
Vue.component('v-nav-item', vNavItem)
Vue.component('v-messages', vMessages)
Vue.component('v-collapse', vCollapse)
Vue.component('v-select', vSelect)
Vue.component('v-tooltip', vTooltip)
Vue.component('v-number', vNumber)
