import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { VariantsizeOption } from '@ts/shared/interfaces'

@Component
export default class Sizes extends Vue {
  @Prop() options!: VariantsizeOption[]
  @Prop({ required: false, default: null }) selectedOption!: VariantsizeOption

  private get sortedOptions(): VariantsizeOption[] {
    return this.options.sort((a, b) => {
      const sizeA = parseInt(a.label, 10)
      const sizeB = parseInt(b.label, 10)

      return sizeA - sizeB
    })
  }

  private select(option: VariantsizeOption) {
    this.$emit('input', { hash: option.hash, updates: { size: option.id } })
  }

  private isOptionSelected(option: VariantsizeOption) {
    return option.hash === this.selectedOption.hash
  }
}
