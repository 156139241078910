import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Variant, Material, SizeOption } from '@ts/shared/interfaces'

@Component
export default class ShapeVariant extends Vue {
  @Prop({ required: true }) data!: Variant
  @Prop({ required: true }) selectedMaterials!: (Material | null)[]
  @Prop({ default: false }) scaleOverride!: SizeOption
  @Prop({
    default: () => {
      return {
        code: '100',
      }
    },
  })
  overrideSize!: SizeOption

  private modifier: number = 0.9

  private get masks() {
    return this.data.masks
  }

  private get selectedSize() {
    return this.scaleOverride ? this.overrideSize : this.data.size
  }

  private get engraveStyle() {
    return {
      'background-image': `url(${this.data.engraveImage})`,
      'background-size': `${this.dimensions * this.modifier}%`,
    }
  }

  private get hasEngraving(): boolean {
    return this.data.details.has_engraving
  }

  private get dimensions(): number {
    return parseInt(this.selectedSize.code, 10)
  }

  private getMask(index: number) {
    return this.masks[index]
  }

  private getMaskStyle(index: number) {
    const mask = this.getMask(index)

    if (mask) {
      return {
        'background-image': `url(${mask})`,
        'background-size': `${this.dimensions * this.modifier}%`,
      }
    }
  }

  private getShapeMaskStyle(index: number) {
    return {
      'mask-image': `url(${this.getMask(index)})`,
      'mask-size': `${this.dimensions * this.modifier}%`,
    }
  }

  private setModifier() {
    const image = new Image()
    image.src = this.getMask(0)

    image.onload = () => {
      if (image.width > image.height) {
        this.modifier = 0.9
      } else {
        const aspect = image.width / image.height
        const offset = 0.9
        this.modifier = aspect * offset
      }
    }
  }

  private mounted() {
    this.setModifier()
  }
}
