import { Component, Prop, Vue } from 'vue-property-decorator'
import { mixin as clickaway } from 'vue-clickaway'
import tippy, { Instance } from 'tippy.js'

@Component({
  mixins: [clickaway],
})
export default class Tooltip extends Vue {
  @Prop({ required: true }) content!: string

  private tippy!: Instance

  private beforeDestroy() {
    this.tippy.destroy()
  }

  private mounted() {
    this.tippy = tippy(this.$el, {
      content: this.content,
      allowHTML: true,
    })
  }
}
