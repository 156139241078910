import Vue from 'vue'
import Component from 'vue-class-component'
import { getSnipcartInstance, Snipcart } from '@plugins/snipcart'
import { http } from '@ts/plugins/http'
import bus from '@shared/bus'

@Component
export default class MixinSnipcart extends Vue {
  private customer: any = null

  private get hasCustomer() {
    return this.customer !== null
  }

  private async customerRegister(customer: any) {
    await http.post('accounts', { email: customer.email })
    _state.isLoggedIn = true
    bus.$emit('login')
  }

  private async customerSignedin(customer: any) {
    await http.post('accounts', { email: customer.email })
    _state.isLoggedIn = true
    bus.$emit('login')
  }

  private async customerSignedout(customer: any) {
    await http.post('accounts/logout')
    _state.isLoggedIn = false
    bus.$emit('logout')
  }

  private bindListeners(snipcart: Snipcart) {
    snipcart.on('customer.registered', this.customerRegister)
    snipcart.on('customer.signedin', this.customerSignedin)
    snipcart.on('customer.signedout', this.customerSignedout)

    const unsubscribe = snipcart.store.subscribe(() => {
      const state = snipcart.store.getState()

      if (state.customer.email) {
        this.customer = state.customer
        unsubscribe()
      }
    })
  }

  private checkSession(snipcart: Snipcart) {
    // check if there is a Snipcart session despite isLoggedIn being false
    if (_state.isLoggedIn) {
      return
    }

    const customer = snipcart.store.getState().customer
    if (customer.email) {
      this.customerSignedin(customer)
    }
  }

  private mounted() {
    getSnipcartInstance()
      .then((instance) => {
        this.bindListeners(instance)
        this.checkSession(instance)
      })
  }
}
