import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Number extends Vue {
  @Prop({ required: true }) value!: number
  @Prop({ default: null }) min!: number
  @Prop({ default: null }) max!: number

  private increment() {
    this.emit(this.value + 1)
  }

  private decrement() {
    this.emit(this.value - 1)
  }

  private inputListener(event: InputEvent) {
    const input = event.target as HTMLInputElement
    const value = parseInt(input.value, 10)

    if (!isNaN(value)) {
      this.emit(value)
    } else {
      this.emit(1)
    }
  }

  private checkValue(event: InputEvent) {
    const input = event.target as HTMLInputElement

    if (input.value.length === 0) {
      input.value = this.value.toString()
    }
  }

  private constrainValue(value: number) {
    if (this.min !== null) {
      value = Math.max(value, this.min)
    }

    if (this.max !== null) {
      value = Math.min(value, this.max)
    }

    return value
  }

  private emit(value: number) {
    this.$emit('input', this.constrainValue(value))
  }
}
